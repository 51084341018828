import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import "./App.css";
import "./css/main.css";
import "./css/fontawesome-all.min.css";
import bagsImg from "./images/bags.png";
import idfBusImg from "./images/idf-bus.png";
import Youtube, { YT } from "react-youtube";
import spotlight from "./images/spotlight.png";

let player;

const Home = () => {
  const videoRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const [contactRef, setContactRef] = useState(null);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && player) {
          player.playVideo();
        }
      },
      { threshold: 0.5 }
    );
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [player]);

  const contactUs = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jc24mmw",
        "template_8fbtlf8",
        contactRef,
        "user_ro01Z52c3g7WktjnrpxMj"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent Successfully!");

          setContactName("");
          setContactEmail("");
          setContactMessage("");

          window.scrollTo(0, 0);
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message, please try again later.");
        }
      );
  };
  const embedContainerStyles = {
    position: "relative",
    paddingBottom: "56.25%",
  };
  const optsAutoplay = isIntersecting
    ? {
        height: "390",
        width: "640",
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          mute: 1,
        },
      }
    : {
        height: "390",
        width: "640",
      };

  const optsShort = {
    width: "390",
    height: "640",
  };

  const optsVideo = {
    height: "390",
    width: "640",
  };
  return (
    <>
      <div id="header">
        <h1 ID="levi">Levi Yitzchok</h1>
        <div className="fadeIn"></div>
        <img className="spotlight bottom" src={spotlight}></img>
        <img className="spotlight top" src={spotlight}></img>
      </div>

      <div id="main">
        <header className="major container medium">
          <h2>Welcome to Levi Yitzchok Cohen's Music</h2>
          <p>
            Discover the musical journey of Levi Cohen, a professional Jewish
            singer and musician who captivates audiences with his unique voice
            and style. Explore his repertoire, watch his videos, and find out
            how you can book him for your next event.
          </p>
        </header>

        <div className="box alt container">
          <header>
            <h2>Latest Videos</h2>
          </header>
          <section className="feature right">
            <div className="image video" ref={videoRef}>
              <Youtube
                videoId={"OwIDyQ-SJWY"}
                opts={optsAutoplay}
                className="youtubeIframe"
                onReady={(event) => {
                  player = event.target;
                }}
              />
            </div>
            <div className="content">
              <h3>Levi Yitzchok performs with Avraham Fried!</h3>
              <p>
                Thankful to Hashem for professional opportunities, they can
                really bring light into the world
              </p>
            </div>
          </section>

          <section className="feature short left">
            <div className="image short">
              <Youtube
                videoId={"lSCY_9HJg4w"}
                opts={optsShort}
                className="youtubeIframe"
              />{" "}
            </div>
            <div className="content">
              <h3>stuff</h3>
              <p>a bit about the video</p>
            </div>
          </section>
          <section className="feature right">
            <div className="image video">
              <Youtube
                videoId={"RjzXlvh-DCI"}
                opts={optsVideo}
                className="youtubeIframe"
              />{" "}
            </div>
            <div className="content">
              <h3>Stuff</h3>
              <p>a bit about the video</p>
            </div>
          </section>
        </div>

        <div className="box container">
          {" "}
          <header>
            {" "}
            <h2>Follow Levi Yitzchok</h2>{" "}
            <h3>Be a part of his musical journey.</h3>{" "}
          </header>{" "}
          <ul className="actions special">
            <li style={{ Align: "center" }}>
              <ul className="icons">
                <li>
                  <a
                    href="https://wwwyoutube.com/iyitzchokic"
                    target="_blank"
                    rel="noopeneroreferrer"
                    className="icon brands fa-youtube"
                  >
                    <span className="label">YouTube</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://wwwyoutube.com/iyitzchokic"
                    target="_blank"
                    rel="noopeneroreferrer"
                    className="icon brands fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://open.spotify.com/artist/leviyitzchokmusic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-spotify"
                  >
                    <span className="label">Spotify</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://soundcloud.com/leviyitzchokmusic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-soundcloud"
                  >
                    <span className="label">SoundCloud</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://music.apple.com/us/artist/leviyitzchokmusic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-apple"
                  >
                    <span className="label">Apple Music</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div id="footer">
        <div className="container medium">
          <header className="major last">
            <h2>Contact us</h2>
          </header>

          <p>
            Use this contact form to get in touch with musician Levi Cohen. Send
            booking inquiries, interview requests, ideas for collaborations,
            licensing proposals, or other opportunities. Fan mail is also
            welcomed.
          </p>
          <p>
            Levi and his team aim to respond promptly to messages sent here.
            Please include key details about your request or proposal so they
            can best assess if and how Levi can participate.
          </p>
          <form ref={setContactRef}>
            <div className="row" id="contact-us">
              <div className="col-6 col-12-mobilep">
                <input
                  type="text"
                  name="from_name"
                  placeholder="Name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
              </div>
              <div className="col-6 col-12-mobilep">
                <input
                  type="email"
                  name="from_email"
                  placeholder="Email"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </div>
              <input
                style={{ position: "fixed", top: 9999999 }}
                name="subject"
                value="Levi Cohen"
                tabIndex="-1"
                onChange={() => {}}
              />
              <div className="col-12">
                <textarea
                  name="message"
                  placeholder="Message"
                  rows="6"
                  value={contactMessage}
                  onChange={(e) => setContactMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="col-12">
                <ul className="actions special">
                  <li>
                    <input
                      type="submit"
                      value="Send Message"
                      onClick={contactUs}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </form>
          <ul className="icons">
            <li>
              <a
                target="_blank"
                href="https://twitter.com/code_israel"
                className="icon brands fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/codeisraelorg"
                className="icon brands fa-facebook-f"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/its.levi.cohen/?hl=en"
                className="icon brands fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://wa.link/yuswza"
                className="icon brands fa-whatsapp"
              >
                <span className="label">WhatsApp</span>
              </a>
            </li>
          </ul>
          <ul className="copyright">
            <li>
              &copy; Site built and developed by{" "}
              <a href="https://www.instagram.com/israeli_nachum/">
                Nachum Freedman
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Home;
