import React, { useEffect } from "react";
import "./Gallery.css";

const Gallery = () => {
  useEffect(() => {
    let script;

    setTimeout(() => {
      // Create a script element
      script = document.createElement("script");
      script.src = "https://www.juicer.io/embed/code-israel/embed-code.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        document
          .querySelector(".juicer-container")
          .insertBefore(
            document.querySelector(".juicer-feed"),
            document.querySelector(".juicer-container").firstChild
          );
      };

      // Append the script to the body
      document.querySelector(".juicer-container").appendChild(script);
    }, 200);

    // Clean up
    return () => {
      //script && document.querySelector('.juicer-container').removeChild(script);
    };
  }, []);

  return (
    <div className="juicer-container">
      <div className="instagram-feed-container">
        <div id="juicer-feed" className="instagram-feed-container"></div>
      </div>

      <div id="footer">
        <ul className="icons">
          <li>
            <a
              target="_blank"
              href="https://twitter.com/code_israel"
              className="icon brands fa-twitter"
            >
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/codeisraelorg"
              className="icon brands fa-facebook-f"
            >
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/code.israel/"
              className="icon brands fa-instagram"
            >
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://wa.link/yuswza"
              className="icon brands fa-whatsapp"
            >
              <span className="label">Github</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://wa.me/c/972508487330"
              className="icon brands fa-shopify"
            >
              <span className="label">Shop</span>
            </a>
          </li>
        </ul>

        <ul className="copyright">
          <li>
            &copy; Site built and developed by{" "}
            <a href="https://www.instagram.com/israeli_nachum/">
              Nachum Freedman
            </a>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Gallery;
