import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  unstable_useViewTransitionState,
} from "react-router-dom";
import Home from "./Home";
import Gallery from "./Gallery";
import Team from "./Team";
import { HashLink } from "react-router-hash-link";
import ReactGA from "react-ga";
import { slide as Menu } from "react-burger-menu";

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.initialize("GT-NSL3JX5");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    console.log(isMobile);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleStateChange = ({ isOpen }) => {
    setIsMenuOpen(isOpen);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const RedirectToWhatsApp = () => {
    useEffect(() => {
      console.log("Redirecting to WhatsApp...");

      window.location.href = "https://wa.me/c/972508487330";
    }, []);

    return null;
  };

  return (
    <>
      {isMobile ? (
        <Menu
          isOpen={isMenuOpen}
          elastic={true}
          right
          onStateChange={handleStateChange}
        >
          <a id="home" onClick={closeMenu} className="menu-item" href="/">
            <span>Home</span>
          </a>
          <a
            id="gallery"
            onClick={closeMenu}
            className="menu-item"
            href="#/gallery"
          >
            <span>Gallery</span>
          </a>
          {/* <a id="team" onClick={closeMenu} className="menu-item" href="#/team">
            <span>Team</span>
          </a> */}
          <HashLink
            smooth
            to="/#contact-us"
            className="menu-item"
            onClick={closeMenu}
          >
            <span>Contact Us</span>
          </HashLink>
        </Menu>
      ) : (
        <nav className="navbar">
          <ul className={`menu ${isMenuOpen && "open"}`}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#/gallery">Gallery</a>
            </li>

            {/* <li>
              <a href="#/team">Team</a>
            </li> */}

            <li
              onClick={() => {
                navigate("/");
                setTimeout(
                  () =>
                    window.scrollTo({
                      top: 6000,
                      left: 0,
                      behavior: "smooth",
                    }),
                  100
                );
              }}
            >
              <a>Contact Us</a>
            </li>
          </ul>
        </nav>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/order" element={<RedirectToWhatsApp />} />
        {/* <Route path="/team" element={<Team />} /> */}
      </Routes>
    </>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
